// Bengali
export default{
    form: {
        startSurveyButton: "চলুন শুরু করি",
        nextButton: "পরবর্তী",
        submitAnswerButton: "উত্তর জমা দিন",
        continueButton: "চালিয়ে যান",
        finishButton: "শেষ করুন",
        headerPercentageCompletion: 'সম্পন্ন',
        headerQuestionsCompleted: 'প্রশ্ন',
        headerDefaultErrorMessage: 'ওপস! কিছু ভুল হয়েছে।',
        brandingPoweredBy: 'দ্বারা চালিত',
        brandingPromoWhySurvey: 'যখন আপনি <b>{product}</b> ব্যবহার করতে পারেন, তাহলে কেন জরিপ করবেন?',
        brandingPromoGetStarted: 'আজই শুরু করুন!',
        submissionPending: "আপনার ভয়েসফর্ম জমা হচ্ছে। অনুগ্রহ করে আপনার ব্রাউজার উইন্ডো বন্ধ করবেন না।",
        submissionCanCloseWindow: "আপনার ভয়েসফর্ম সফলভাবে জমা হয়েছে। আপনি এখন উইন্ডোটি বন্ধ করতে পারেন 👍",
        mediaAttachmentOverlayCloseButton: "বন্ধ করুন",
        expiredFormHeader: "থামার জন্য ধন্যবাদ!",
        expiredFormMessage: "দেখা যাচ্ছে এই জরিপটির মেয়াদ শেষ হয়ে গেছে। অনুগ্রহ করে ভয়েসফর্ম নির্মাতাকে জানাতে যোগাযোগ করুন।",
        notPublishedFormHeader: "ভয়েসফর্ম প্রকাশিত হয়নি!",
        notPublishedFormMessage: "দেখা যাচ্ছে এই জরিপটি প্রকাশিত হয়নি। অনুগ্রহ করে ভয়েসফর্ম নির্মাতাকে জানাতে যোগাযোগ করুন।",
        notPublishedFormRetryButton: "আবার চেষ্টা করুন",
        errorFormHeader: "ওপস!",
        errorFormMessage: "কিছু ভুল হয়েছে। অনুগ্রহ করে পরে আবার চেষ্টা করুন।",
        errorFormRetryButton: "আবার চেষ্টা করুন",
        emptyFormHeader: "থামার জন্য ধন্যবাদ!",
        emptyFormMessage: "ওপস! এই ফর্মে কোনো প্রশ্ন বা পূরণের ক্ষেত্র নেই। অসুবিধার জন্য আমরা দুঃখিত। অনুগ্রহ করে ফর্মের নির্মাতার সাথে যোগাযোগ করুন এবং তাদের এই সমস্যা সম্পর্কে জানান। ধন্যবাদ আপনার বোঝাপড়া ও সহযোগিতার জন্য!",

        restoreFormHeader: "আপনার একটি প্রক্রিয়াধীন ফর্ম রয়েছে বলে মনে হচ্ছে!",
        restoreFormMessage: "আপনি কি আগের যেখানে শেষ করেছেন সেখান থেকে শুরু করতে চান?",
        restoreFormButton: "ফর্ম চালিয়ে যান",
        restoreFormButtonNew: "পুনরায় শুরু করুন",

        audioPermissionHeader: "আপনি কি কণ্ঠ দিয়ে উত্তর দিতে চান?",
        audioPermissionMessage: "কথা বললে দ্রুত ও সহজে উত্তর দিতে পারবেন।",
        audioPermissionAcceptButton: "হ্যাঁ, আমি চাই",
        audioPermissionDenyButton: "না, ধন্যবাদ",
        audioPermissionErrorHeader: "মাইক্রোফোন উপলব্ধ নেই",
        audioPermissionErrorMessage: "মাইক্রোফোন অ্যাক্সেস সক্ষম করতে অনুগ্রহ করে আপনার ব্রাউজারের অনুমতিগুলি পরীক্ষা করুন।",
        audioPermissionErrorButton: "ঠিক আছে",
        audioRecorderFailedUpload: "রেকর্ডিং আপলোড করতে ব্যর্থ হয়েছে",
        silenceDetectorError: "আপনার কণ্ঠ শোনা যাচ্ছে না। অনুগ্রহ করে আপনার মাইক্রোফোনের পছন্দসমূহ পরীক্ষা করুন। পরামর্শ: এটি আপনার বাইরের মাইক্রোফোন হতে পারে।",
        audioRecorderButtonRecord: "রেকর্ড করুন",
        audioRecorderButtonRecordMore: "আরো রেকর্ড করুন",
        voiceResponseSelectionMessage: "আপনার উত্তর দেওয়ার পদ্ধতি নির্বাচন করুন...",
        voiceResponseSelectionOrDivider: "অথবা",
        voiceResponseTextInputPlaceholder: "উত্তর টাইপ করুন",
        voiceResponseTextInputCharacterCounterMinMessage: "অনুগ্রহ করে অন্তত {min} অক্ষর লিখুন",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} অক্ষর (সর্বনিম্ন {min} অক্ষর)",
        voiceResponseTextInputCharacterValidationMessage: "{count} অক্ষর (সর্বনিম্ন {min} অক্ষর)",

        // checkbox
        checkboxValidationTooFew: "আপনাকে অন্তত {min} বিকল্প নির্বাচন করতে হবে",
        checkboxValidationTooMany: "আপনাকে সর্বাধিক {max} বিকল্প নির্বাচন করতে হবে",
        checkboxNoneOfTheAboveOption: "উপরের কোনোটিই নয়",
        checkboxInvalidAnswer: "অবৈধ উত্তর।",

        // datepicker
        datePickerPlaceholder: "তারিখ নির্বাচন করুন",

        // dropdown
        // email
        emailLabel: "ইমেইল",
        emailInvalid:"অবৈধ ইমেইল।",
        // file-upload
        fileUploadPluginNameCamera: "ক্যামেরা",
        fileUploadPluginNameCameraVideo: "ভিডিও রেকর্ড করুন",
        fileUploadPluginScreenCast: "স্ক্রিন কাস্ট",
        fileUploadVideoImportFilesDefault: 'ভিডিও রেকর্ড বা আপলোড করুন:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'ভিডিও রেকর্ডিং বিকল্প নির্বাচন করুন:',
        fileUploadVideoImportFilesNoCamera: 'স্ক্রিন রেকর্ড করতে বোতামে চাপুন',
        fileUploadVideoImportFilesNoScreenCast: 'ভিডিও রেকর্ড করতে বোতামে চাপুন',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'ভিডিও রেকর্ড করার কোনো বিকল্প নেই',

        // matrix
        matrixValidationMessage: "উত্তর বৈধ।",
        matrixRow: "সারি",
        matrixColumn: "কলাম",
        matrixRowRequired: "সারি {rowTitle} প্রয়োজন।",
        matrixRadioGroup: "রেডিও গ্রুপ",
        matrixCheckboxGroup: "চেকবক্স গ্রুপ",
        matrixGroupRequired: "সারি {rowTitle} এর জন্য। {type} {groupTitle} প্রয়োজন।",
        matrixColumnRequired: "সারি {rowTitle} এর জন্য। কলাম {columnTitle} প্রয়োজন।",
        matrixColumnInvalid: "সারি {rowTitle} এর জন্য। কলাম {columnTitle} অবৈধ।",
        matrixRequired: "প্রয়োজন।",
        matrixNumericMustBeNumber: "সংখ্যা হতে হবে।",
        matrixNumericMustBeGreaterThenMin: "{min} এর চেয়ে বড় হতে হবে।",
        matrixNumericMustBeLessThenMax: "{max} এর চেয়ে ছোট হতে হবে।",
        matrixNumericMustBeInteger: "পূর্ণসংখ্যা হতে হবে।",
        matrixNumericInvalidNumber: "অবৈধ সংখ্যা।",

        // name
        nameLabel: "নাম",
        nameInvalid: "প্রথম এবং শেষ নাম উল্লেখ করুন।",

        // nps
        npsNotLikely: "একেবারেই সম্ভাবনা নেই",
        npsExtremelyLikely: "অত্যন্ত সম্ভাবনা",

        // numeric input
        numericInputRequired: "প্রয়োজন।",
        numericInputMustBeNumber: "সংখ্যা হতে হবে।",
        numericInputMustBeGreaterThenMin: "{min} এর চেয়ে বড় হতে হবে।",
        numericInputMustBeLessThenMax: "{max} এর চেয়ে ছোট হতে হবে।",
        numericInputMustBeInteger: "পূর্ণসংখ্যা হতে হবে।",
        numericInputInvalidNumber: "অবৈধ সংখ্যা।",
        numericInputPlaceholder: "সংখ্যা টাইপ করুন",

        // phone
        phoneInvalid: "ফোন নম্বর অবৈধ।",
        phoneCountrySelectorLabel: 'দেশের কোড',
        phoneCountrySelectorError: 'দেশ নির্বাচন করুন',
        phoneNumberLabel: 'ফোন নম্বর',
        phoneExample: 'উদাহরণ:',

        // boolean
        booleanYesLabel: "হ্যাঁ",
        booleanNoLabel: "না",

        //questionStep
        questionStepAudioQuestionLabel: "অডিও প্রশ্ন",

        // errors
        invalidPhoneNumber: "{phone} একটি অবৈধ ফোন নম্বর।",
        invalidEmail: "{email} একটি অবৈধ ইমেল ঠিকানা।",
        questionIsRequired: "প্রশ্নটি প্রয়োজন।",
        answerIsNotValid: "উত্তরটি বৈধ নয়।",
        unfinishedProbingDialogError: "অনুগ্রহ করে সংলাপটি শেষ করুন।",
        cannotResumePartialResponse: "আংশিক প্রতিক্রিয়া পুনরায় শুরু করা যাবে না।",
        failedToSubmitForm: "ফর্ম জমা দিতে ব্যর্থ হয়েছে। অনুগ্রহ করে আপনার ইন্টারনেট সংযোগ পরীক্ষা করুন এবং আবার চেষ্টা করুন।",

        //language picker search language
        searchLanguage: "ভাষা অনুসন্ধান",
        
    }
}
